<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-08-04 14:16:02
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-04 15:32:57
-->
<template>
  <div class="acknowled_discount">
    <a-card :border="false">
      <!-- 搜索 -->
      <OrderSearch :showOrderStatus="'order_info'"></OrderSearch>
      <!-- 操作按钮 -->
      <div>
        <a-button type="primary" @click="onShow">生成发货单</a-button>
      </div>
      <!-- 数据表格 -->
      <OrderList
        :url="'/api/order/order/orderInfo/consolidatedOrder'"
        :columnsData="columns"
        :status="[]"
        @orderData="getSelectedRows($event)"
      ></OrderList>
      <!-- 生成发货单 -->
      <GenerateInvoice ref="GenerateInvoice"></GenerateInvoice>

    </a-card>
  </div>
</template>

<script>
import GenerateInvoice from '../to_delivery_order/components/GenerateInvoice.vue'

export default {
  name: 'acknowled_discount',

  components: {
    GenerateInvoice
  },

  data() {
    return {
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        'orderCode',
        'bigAreaName',
        'dealerName',
        'flagFreeShipping',
        'deliveryProperties',
        'purchaseAndSalesContractType',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        // 'orderStatus',
        'expectTime',
        'createTime',
        'address',
        'consigneeType',
        'supplierName',
        'policyTotalAmount',
        'rebateTotalAmount',
      ],
    }
  },

  computed: {},

  created() {},

  methods: {
    getSelectedRows(rows) {
      this.selectedRows = rows
    },

    onShow() {
      if(this.selectedRows.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      this.$refs.GenerateInvoice.setRowData(this.selectedRows[0], 'generate')
    },
  },
}
</script>

<style lang='scss' scoped>
</style>