<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-24 09:51:47
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-01 14:36:15
-->
<template>
  <div class="">
    <a-modal
      :maskClosable="false"
      title="操作"
      style="top: 8px"
      width="85%"
      v-model="visible"
      :confirmLoading="isLoading"
      @cancel="toCancel"
      @ok="toSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form-model ref="form" :rules="rules" :model="rowData" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
          <a-row :gutter="4">
            <a-col :md="12">
              <a-form-model-item label="订单编号">
                <a-input v-model.trim="rowData.orderCode" placeholder="订单编号" :max-length="500" disabled></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="销售合同">
                <a-input v-model.trim="rowData.xsHeTong" placeholder="销售合同" :max-length="500" disabled></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="4">
            <a-col :md="12">
              <a-form-model-item label="采购合同">
                <a-input v-model.trim="rowData.cgHeTong" placeholder="采购合同" :max-length="500" disabled></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="4">
            <a-col :md="12">
              <a-form-model-item label="联系人">
                <a-input v-model.trim="rowData.customerName" placeholder="联系人" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="联系电话">
                <a-input v-model.trim="rowData.customerContact" placeholder="联系电话" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="4">
            <a-col :md="12">
              <a-form-model-item label="地址">
                <PCD
                  ref="pcd"
                  placeholder="请选择区域"
                  :province.sync="rowData.province"
                  :city.sync="rowData.city"
                  :district.sync="rowData.areaName"
                  :provinceId.sync="rowData.provinceId"
                  :cityId.sync="rowData.cityId"
                  :districtId.sync="rowData.areaId"
                >
                </PCD>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="详细地址">
                <a-textarea v-model.trim="rowData.address" placeholder="详细地址" :max-length="100"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="发货日期" prop="deliveryTime">
                <DatePicker :startTime.sync="rowData.deliveryTime" :currentTime.sync="currentTime"></DatePicker>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" v-if="rowData.supplierCode != 'shuntian'">
              <a-form-model-item label="仓库">
                <a-select placeholder="请选择仓库" v-model="rowData.warehouseId">
                  <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="24">
              <a-form-model-item label="订单备注" :labelCol="{ span: 3 }" :wrapperCol="{ span: 21 }">
                <a-textarea placeholder="订单备注" v-model.trim="rowData.remark" disabled></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-tabs default-active-key="0" size="small" type="card">
            <a-tab-pane
              :key="firstIndex"
              :tab="orderCode.substring(orderCode.lastIndexOf('卐') + 1, orderCode.length)"
              force-render
              v-for="(item, orderCode, firstIndex) in details"
            >
              <a-table :data-source="item" :rowKey="(record) => record.id" :pagination="false" bordered>
                <a-table-column title="商品图片" data-index="proPicture" align="center">
                  <template slot-scope="text">
                    <ZoomMedia style="height: 20px" :value="text"></ZoomMedia>
                  </template>
                </a-table-column>
                <a-table-column title="商品名称" data-index="proName" align="center"></a-table-column>
                <a-table-column title="分类" data-index="proOrgCodeName" align="center"></a-table-column>
                <a-table-column title="规格" data-index="specValue" align="center"></a-table-column>
                <a-table-column title="品牌" data-index="proBrandName" align="center"></a-table-column>
                <a-table-column title="单位" data-index="proUnitVo.name" :width="125" :ellipsis="true" align="center">
                  <template slot-scope="text, row">
                    <span v-if="row.proUnitVo.point != 0"
                      >{{ row.proUnitVo.name }} <span>(保留{{ row.proUnitVo.point }}小数)</span></span
                    >
                    <span v-if="row.proUnitVo.point == 0">{{ row.proUnitVo.name }} <span>(保留整数)</span></span>
                  </template>
                </a-table-column>
                <a-table-column title="建议零售价" data-index="proPrice" align="center">
                  <template slot-scope="text">{{ text.toFixed(2) | formatMoney }}</template>
                </a-table-column>
                <a-table-column title="成交价" data-index="proCostPrice" align="center">
                  <template slot-scope="text">{{ text.toFixed(2) | formatMoney }}</template>
                </a-table-column>
                <a-table-column title="购买数量" data-index="purchaseNum" align="center"></a-table-column>
                <a-table-column title="已发货" data-index="alreadyShipNum" align="center"></a-table-column>
                <a-table-column title="剩余可发货" data-index="remainingNum" align="center"> </a-table-column>

                <a-table-column title="数量" data-index="" align="center" :width="120">
                  <template slot-scope="text, row">
                    <a-input-number
                      v-model="row.shipNum"
                      :min="row.remainingNum < 0 ? row.remainingNum : 0"
                      :max="row.remainingNum > 0 ? row.remainingNum : 0"
                      :step="row.proUnitVo.point == 0 ? 1 : 1 / Math.pow(10, row.proUnitVo.point)"
                      :precision="row.proUnitVo.point"
                      @change="getShipNum(text, row)"
                    ></a-input-number>
                  </template>
                </a-table-column>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </a-form-model>
      </a-spin>
    </a-modal>
    <Drawer @callbackComponent="callbackComponent" ref="_Drawer" />
  </div>
</template>

<script>
import { stringify } from 'qs'
import { selectByIdOrderShipInfo } from '../api/OrderInfoApi'
export default {
  name: '',
  components: { Drawer: () => import('./ConfirmDrawer') },
  data() {
    return {
      showLogisticsRemark: true,
      handle: '',
      isLoading: false,
      visible: false,
      rowData: {},
      deliverys: {},
      tableData: [],
      details: {},
      LogisticsType: [
        {
          id: 1,
          name: '自主查询',
        },
        {
          id: 2,
          name: '手动查询',
        },
      ],
      warehouseList: [],
      currentTime: '',
      spinning: false,
      rules: {
        deliveryTime: [{ required: true, message: '请填写发货日期', tirgger: 'change' }],
      },
      price_sum: null,
    }
  },

  computed: {},
  created() {},
  methods: {
    callbackComponent(params) {
      if (params.function) {
        this[params.function](params.data)
      }
    },

    setRowData(row, handle) {
      let _this = this
      this.visible = true
      this.handle = handle
      _this.spinning = true
      this.getDataTime()
      let ids = row.map((item) => item.id)
      if (handle === 'generate') {
        selectByIdOrderShipInfo(ids)
          .then((res) => {
            this.rowData = res.body
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
            this.details = this.rowData.detailsMap
            _this.spinning = false
          })
          .catch((e) => {
            _this.spinning = false
          })
      }
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '菱感仓库').then((res) => {
        // 获取字典仓库
        this.warehouseList = res.body
      })
    },

    toCancel() {
      this.visible = false
    },
    // 获取限制时间
    getDataTime() {
      this.axios.get('/api/order/order/orderInfo/getPaymentDaysOn').then((res) => {
        this.currentTime = res.body || ''
      })
    },

    onBrandChange(val) {
      this.rowData.deliveryId = val.key
      this.rowData.deliveryName = val.label
    },

    onChange(val) {
      if (val === 1) {
        this.showLogisticsRemark = false
      } else {
        this.showLogisticsRemark = true
      }
      this.axios.get('/api/order/logistics/logisticsCompany/list', { params: { queryType: val } }).then((res) => {
        this.deliverys = res.body.records
      })
    },
    getShipNum(num, row) {
      let pirce = row.shipNum * row.proCostPrice
      this.price_sum += Number(pirce)
      // console.log(typeof pirce, 'pirce')
      // console.log(typeof this.price_sum, 'this.price_sum')
    },
    openDrawer(res, proSum) {
      this.$nextTick(() => {
        this.$refs._Drawer.showDrawer(res, proSum)
      })
    },
    // 提交
    toSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.rowData.supplierCode != 'shuntian') {
            if (!this.rowData.warehouseId) {
              return this.$notification.warning({ message: '请选择仓库' })
            }
          }

          if (!this.rowData.deliveryTime) {
            return this.$notification.warning({ message: '请选择发货日期' })
          }

          //联系人
          if (!this.rowData.customerName) {
            return this.$notification.warning({ message: '请填写联系人' })
          }
          //联系电话
          if (!this.rowData.customerContact) {
            return this.$notification.warning({ message: '请填写联系电话' })
          }
          //收货地址
          if (!this.rowData.provinceId || !this.rowData.cityId || !this.rowData.areaId || !this.rowData.address) {
            return this.$notification.warning({ message: '请填写完整的收货地址' })
          }
          var map = this.details
          var arr = new Array()
          for (var key in map) {
            var orderCode = key
            var detail = map[orderCode] //获取对应的value值
            for (var i = 0; i < detail.length; i++) {
              detail[i].productName = detail[i].goodsName
              arr.push(detail[i])
            }
          }

          let arrList = arr.filter((item) => {
            return item.shipNum === null || item.shipNum === 0
          })
          let proSum = 0
          arr.forEach((element) => {
            proSum += element.shipNum
          })
          if (proSum <= 0) {
            return this.$notification.warning({ message: '发货单数量不能为空' })
          }

          let itemArr = arr.map((item) => {
            if (item.proUnitVo.point > 0) {
              var s = '^\\d+(\\.\\d{1,' + item.proUnitVo.point + '})?$'
              this.messageArr = '请输入对应的' + item.proUnitVo.point + '位小数'
            } else if (item.proUnitVo.point == 0) {
              var s = /^(-|\+)?\d+$/
              this.messageArr = '请输入整数'
            }
            var re = new RegExp(s)
            return re.test(item.shipNum)
          })

          if (itemArr[0] === false) {
            return this.$notification.warning({ message: this.messageArr })
          }
          this.rowData.orderInfoDetailVOList = arr
          this.isLoading = true
          console.log(JSON.parse(JSON.stringify(this.rowData)))

          //计算发货单可用额度等信息
          this.axios
            .post(`/api/order/system/orderShipInfo/prepareShipOrderInfo`, this.rowData)
            .then((res) => {
              if (res.code == 200) {
                // this.$message.success(res.message)
                // 打开抽屉
                this.openDrawer(res, proSum)
                this.$emit('reload')
                // this.$bus.$emit('getList')
              }
            })
            .catch((err) => {})
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    // 获取 抽屉组件传递的信息 最终的请求
    sendOrderShipIfo(data) {

      if (data.price && data.res_data && this.rowData) {
        // 插入抽屉数据
        this.rowData.proLimitList = data.res_data.ProList
        this.rowData.cateLimitList = data.res_data.AllType
        this.rowData.allLimitList = data.res_data.AllPro
        this.rowData.actualPrice = data.price.actualPrice
        this.rowData.actualPriceBalance = data.price.actualPriceBalance
        this.rowData.actualPriceLimit = data.price.actualPriceLimit

      } else {
        this.$message.success('错误参数')
        return false
      }
      // if (this.rowData.limitMap) {
      //   for (const key in this.rowData.limitMap) {
      //     if (this.rowData.limitMap[key].length <= 0) {
      //       delete this.rowData.limitMap[key]
      //     }
      //   }
      // }
      console.log(JSON.parse(JSON.stringify(this.rowData)), '插入后的数据')
      this.axios
        .post(`/api/order/system/orderShipInfo/addOrderShipIfo`, this.rowData)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.visible = false
            // 关闭抽屉
            this.$nextTick(() => {
              if (this.$refs._Drawer) {
                this.$refs._Drawer.onClose()
              }
            })
            // this.$emit('reload')
            // this.$bus.$emit('getList')
          }
        })
        .catch((err) => {
          this.$nextTick(() => {
            if (this.$refs._Drawer) {
              this.$refs._Drawer.closeLoading()
            }
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onClose() {
      this.visible = false
    },
  },
}
</script>

<style lang='scss' scoped>
</style>